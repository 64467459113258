<template>
  <q-card style="width: 100%; height: 100%">
    <q-tabs
      v-model="references.selectedTab"
      dense
      outside-arrows
      mobile-arrows
      align="left"
      :class="$q.dark.isActive ? 'white' : 'text-grey'"
      active-color="primary"
      indicator-color="primary"
      narrow-indicator
    >
      <q-tab name="references" label="References"> </q-tab>
      <q-tab name="referencedBy" label="referenced By"> </q-tab>
      <q-tab name="matrix" label="view Matrix Files"> </q-tab>
      <q-tab name="matrixtree" label="Full Matrix"> </q-tab>
    </q-tabs>
    <q-tab-panels v-model="references.selectedTab" animated>
      <q-tab-panel name="references">
        <editor
          class="q-mt-sm"
          v-for="pFile in references.refers"
          :key="pFile.path"
          :instancename="'r1editor_' + indexPaneIndex + '_' + pFile.ics"
          :file="pFile"
          :fullHeight="false"
        />
      </q-tab-panel>
      <q-tab-panel name="referencedBy">
        <editor
          class="q-mt-sm"
          v-for="pFile in references.referedBy"
          :key="pFile.path"
          :instancename="'r2editor_' + indexPaneIndex + '_' + pFile.ics"
          :file="pFile"
          :fullHeight="false"
        />
      </q-tab-panel>
      <q-tab-panel name="matrix">
        <editor
          class="q-mt-sm"
          v-for="pFile in references.matrix"
          :key="pFile.path"
          :instancename="'r3editor_' + indexPaneIndex + '_' + pFile.ics"
          :file="pFile"
          :fullHeight="false"
        />
      </q-tab-panel>
      <q-tab-panel name="matrixtree">
        <matrix
          class="q-mt-sm"
          :instancename="'matrix_' + indexPaneIndex"
          :tree="references.tree"
          :indexPaneIndex="indexPaneIndex"
        />
      </q-tab-panel>
    </q-tab-panels>
  </q-card>
</template>

<script lang="coffee">
import { defineComponent, ref, onMounted,onUnmounted,watch, reactive,defineAsyncComponent } from 'vue'
import {References} from "./referencesCls.coffee"
import editor from './editor/editor.vue'

import matrix from './matrix.vue'
export default defineComponent({
  name: 'references',
  components: {editor,matrix}
  props:
    instancename:
      default: 'references'
      type: String
    file:
      default: {}
      type: Object
    indexPaneIndex:
      type: String
      default: "0"
  setup: (props) ->
    references = reactive(new References({props:props}))
    references.vueBinder()
    onMounted(() => references.mounted())
    onUnmounted(() => references.unmounted())
    watch( () =>
      props.file
    ,(current,old) =>
      references.watcher(current)
    ,{immediate:true}
    )
    return {references}

})
</script>

<style scoped></style>
