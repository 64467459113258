<template>
  <div class="q-pa-md q-gutter-sm">
    <q-input ref="filterRef" filled v-model="filter" label="Filter" dense>
      <template v-slot:append>
        <q-icon
          v-if="filter !== ''"
          name="clear"
          class="cursor-pointer"
          @click="resetFilter"
        />
      </template>
    </q-input>

    <q-tree
      ref="mtree"
      dense
      default-expand-all
      :nodes="tree"
      node-key="key"
      :filter="filter"
      :filter-method="firstLevelFilter"
      v-model:selected="selected"
      @update:selected="nodeClicked"
    >
      <template v-slot:default-header="prop">
        <div class="row no-wrap items-center" style="width: 100%">
          <q-icon
            :name="prop.node.icon"
            :color="prop.node.tlevel == 1 ? 'orange' : 'primary'"
            :size="prop.node.tlevel == 1 ? '16px' : '14px'"
            class="q-mr-xs"
          />
          <div
            :class="
              prop.node.tlevel == 1
                ? 'text-weight-bold'
                : 'text-weight-light  text-primary'
            "
          >
            {{ prop.node.label }}
          </div>
        </div>
      </template>
    </q-tree>
  </div>
</template>

<script lang="coffee">
import { ref,defineComponent } from 'vue'
import ComponentHub from "core/componentsHub.coffee"

export default defineComponent {
  name: 'references',
  props:
    instancename:
      default: 'matrix'
      type: String
    tree:
      default: []
      type: Object
    indexPaneIndex:
      type: String
      default: "0"

  setup: (props) ->
    mtree = ref(null)
    filter = ref('')
    filterRef = ref(null)
    selected = ref(null)
    nodeClicked = (key) ->
        if key?
          ics = key.split(":")[1]
          if ics isnt "root"
            doc = ComponentHub.loadedPlugins.pandora.folders[mtree.value.getNodeByKey(key).projectName].docByIcs ics
            ComponentHub.PageIndex.selectNode doc
    return {
      mtree,
      filter,
      filterRef,
      selected,
      firstLevelFilter: (node, filter) ->
        filt = filter.toLowerCase()
        return (node.label and node.label.toLowerCase().indexOf(filt) > -1 and node.tlevel is 1) or
          (node.parent and node.parent.toLowerCase().indexOf(filt) > -1)
      ,
      resetFilter: ->
        filter.value = ''
        filterRef.value.focus()
      ,
      nodeClicked
    }

}
</script>
