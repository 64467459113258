<template>
  <q-dialog v-model="dialogcontainer.openFileDialog">
    <q-card class="file-card" style="min-width: 1000px">
      <q-card-section>
        <newfiledialog
          :parent="dialogcontainer.selectedTreeNode"
          @changed="dialogcontainer.newFileParamsChanged"
          @invalidated="dialogcontainer.newFileInvalid = true"
          @validated="dialogcontainer.newFileInvalid = false"
        >
        </newfiledialog>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          v-close-popup
          flat
          color="primary"
          icon="done"
          label="Create File"
          :disable="dialogcontainer.newFileInvalid"
          @click="dialogcontainer.newFileDialogCloseOK"
        />
        <q-btn
          v-close-popup
          flat
          color="alert"
          round
          icon="cancel"
          @click="dialogcontainer.newFileDialogCloseCancel"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="coffee">
import { defineComponent, ref, onMounted, reactive, onUnmounted,defineAsyncComponent } from 'vue'
import { Dialogcontainer  } from "./dialogcontainerCls.coffee"

import newfiledialog from "./newFile/newFileDialog.vue"
export default defineComponent({
  name: 'dialogcontainer',
  components: {newfiledialog},
  props:
    instancename:
      type: String
      default: 'dialogcontainer'
  setup: (props) ->
    dialogcontainer = reactive(new Dialogcontainer({props:props}))
    dialogcontainer.vueBinder()
    onMounted(() => dialogcontainer.mounted())
    onUnmounted(() => dialogcontainer.unmounted())
    return {dialogcontainer}

})
</script>
