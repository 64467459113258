<template>
  <q-scroll-area
    v-if="!node.tabPinned"
    class="scrollPane"
    ref="browserScroller"
  >
    <div
      :key="vueTrigger"
      class="renderedPreview"
      v-html="container.generateBrowsingTags()"
    ></div>
  </q-scroll-area>
  <!-- PandoraFile-->
  <div style="height: 100%" v-else>
    <div
      style="height: 100%"
      id="pandorafiles"
      v-if="node.mimeType === 'text/pandora-requirement'"
    >
      <splitpanes class="default-theme col panes">
        <pane>
          <q-scroll-area
            class="scrollPane"
            ref="editorScroller"
            @scroll="onScrollEditor"
          >
            <editor
              :instancename="'editor_' + indexPaneIndex + '_' + node.ino"
              :file="node"
              :fullHeight="true"
            />
          </q-scroll-area>
        </pane>
      </splitpanes>
      <!-- References -->

      <Teleport to="#rightSidePaneTarget">
        <div
          style="height: 100%"
          v-if="
            container.ComponentHub.isActiveResource(node.ino, parentPanelId)
          "
        >
          <q-scroll-area v-if="node.editMode" class="scrollPane">
            <references
              :indexPaneIndex="indexPaneIndex.toString()"
              :file="node"
              :instancename="'references_' + indexPaneIndex + '_' + node.ino"
            />
          </q-scroll-area>
          <q-scroll-area
            v-else
            class="scrollPane"
            ref="previewScroller"
            @scroll="onScrollPreview"
          >
            <div
              class="renderedPreview"
              v-html="container.generateShowDownTag()"
            ></div>
          </q-scroll-area>
        </div>
      </Teleport>
    </div>
    <div style="height: 100%" id="nonpandorafiles" v-else>
      <!-- images -->
      <q-scroll-area
        id="images"
        v-if="node.getViewerType() === 'image'"
        class="scrollPane"
      >
        <q-img
          :src="node.getContent()"
          native-context-menu
          spinner-color="white"
          loading="lazy"
          fit="fill"
        ></q-img>
      </q-scroll-area>
      <!-- default editor for everything else -->
      <editorng
        v-else
        :instancename="'editorng_' + indexPaneIndex + '_' + node.ino"
        :file="node"
        :fullHeight="true"
      />
    </div>
  </div>
</template>

<script lang="coffee">
import { defineComponent, ref, onMounted, reactive, onUnmounted ,defineAsyncComponent} from 'vue'
import { Splitpanes, Pane } from 'splitpanes'
import {Container} from "./containerCls.coffee"

import editorng from 'core/editorng.vue'
import editor from './editor/editor.vue'
import references from './references.vue'
export default defineComponent({
  name: 'container',
  components: {
    editor,
    editorng,
    references,
    Splitpanes,
    Pane}
  props:
    instancename:
      default: 'container'
      type: String
    node:
      default: {}
      type:Object
    nodeIndex:
      type: String
      default: ''
    indexPaneIndex:
      type: String
      default: "0"
    parentPanelId:
      type: Number
      default: 0
  setup: (props) ->
    editorScroller = ref(null)
    previewScroller = ref(null)
    vueTrigger=ref(0)
    refs={editorScroller,previewScroller,vueTrigger}
    ignoreSource = null
    container = reactive(new Container({props:props,refs:refs}))
    container.vueBinder()
    onMounted(() => container.mounted())
    onUnmounted(() => container.unmounted())


    scroll = (source, position) =>
      if node? and node.mimeType is 'text/pandora-requirement' and !props.node.editMode
        ###
          if we previously just updated
          the scroll position, then ignore
          this update as otherwise we'll flicker
          the position from one scroll area to
          the other in an infinite loop
          ###
        if ignoreSource is source
          ignoreSource = null
          return
        ###
          we'll now update the other scroll area,
          which will also trigger a @scroll event...
          and we need to ignore that one
          ###
        ignoreSource = if source is 'first' then 'second' else 'first'
        areaRef = if source is 'first' then previewScroller else editorScroller
        areaRef.value.setScrollPosition('vertical', position)

    onScrollEditor = ({ verticalPosition }) ->
        scroll('first', verticalPosition)


    onScrollPreview = ({ verticalPosition }) ->
        scroll('second', verticalPosition)


    return {container, editorScroller, previewScroller,onScrollEditor,onScrollPreview,vueTrigger}

})
</script>

<style lang="sass">




.scrollPane
    height: 100%
    width:100%
body.body--dark
  .renderedPreview
    tr:nth-child(even)
      background-color: #303030 !important
    tr:hover
      background-color: #404040 !important
    th
      background-color: #606070 !important
    blockquote
      border-left: 8px solid #fafafa
    pre, code
      background-color: #303030 !important



.renderedPreview
  margin-left: 20px

@media print
.renderedPreview
  *
    background: transparent !important

    box-shadow: none !important
    text-shadow: none !important

    &:before, &:after
      background: transparent !important

      box-shadow: none !important
      text-shadow: none !important

  a
    text-decoration: underline

    &:visited
      text-decoration: underline



  abbr[title]:after
    content: " (" attr(title) ")"

  a
    &[href^="#"]:after, &[href^="javascript:"]:after
      content: ""

  pre, blockquote

    page-break-inside: avoid

  thead
    display: table-header-group

  tr
    page-break-inside: avoid

  img
    page-break-inside: avoid
    max-width: 100% !important

  p, h2, h3
    orphans: 3
    widows: 3

  h2, h3
    page-break-after: avoid

  table
    font-family: Arial, Helvetica, sans-serif
    border-collapse: collapse
    width: 90%
    margin-bottom: 1.3rem


  thead
    border-radius: 10px

  td, th
    border: 1px solid #ddd
    padding: 8px


  tr:nth-child(even)
    background-color: #f2f2f2 !important



  tr:hover
    background-color: #ddd !important

  th
    padding-top: 12px
    padding-bottom: 12px
    text-align: left
    background-color: #c0c0c0 !important
    font-weight: bold

.renderedPreview
  pre, code
    font-family: Menlo, Monaco, "Courier New", monospace

  pre
    padding: .5rem
    line-height: 1.25
    overflow-x: scroll

  a
    color: #3498db

    &:visited
      color: #3498db

    &:hover, &:focus, &:active
      color: #2980b9

  .modest-no-decoration
    text-decoration: none

  html
    font-size: 12px

  @media screen and (min-width: 32rem) and (max-width: 48rem)
    html
      font-size: 15px

  @media screen and (min-width: 48rem)
    html
      font-size: 16px

  body
    line-height: 1.85

  p, .modest-p
    font-size: 1rem
    margin-bottom: 1.3rem

  h1, .modest-h1, h2, .modest-h2, h3, .modest-h3, h4, .modest-h4
    margin: 1.414rem 0 .5rem
    font-weight: inherit
    line-height: 1.42

  h1, .modest-h1
    margin-top: 0
    font-size: 3.998rem

  h2, .modest-h2
    font-size: 2.827rem

  h3, .modest-h3
    font-size: 1.999rem

  h4, .modest-h4
    font-size: 1.414rem

  h5, .modest-h5
    font-size: 1.121rem

  h6, .modest-h6
    font-size: .88rem

  small, .modest-small
    font-size: .707em

  /* https://github.com/mrmrs/fluidity

  img, canvas, iframe, video, svg, select, textarea
    max-width: 100%

  @import url(http://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300italic,700)

  @import url(http://fonts.googleapis.com/css?family=Arimo:700,700italic)

  html
    font-size: 18px
    max-width: 100%

  body
    color: #444
    font-family: 'Open Sans Condensed', sans-serif
    font-weight: 300
    margin: 0 auto
    max-width: 48rem
    line-height: 1.45
    padding: .25rem

  h1, h2, h3, h4, h5, h6
    font-family: Arimo, Helvetica, sans-serif

  h1, h2, h3
    border-bottom: 2px solid #c0c0c0
    margin-bottom: 1.15rem
    padding-bottom: .5rem
    text-align: center

  blockquote
    border-left: 8px solid #c0c0c0
    padding: 1rem
    border-top-left-radius: 25px
    border-bottom-left-radius: 25px

  pre, code
    background-color: #ddd !important
    max-width: 99%
    border-radius: 25px
</style>
