<template>
  <div>
    <q-input
      dense
      ref="pathRef"
      label="File Path"
      v-model="directory"
      @blur="validate"
      :rules="[
        (val) => !!val || 'Field is required',
        (val) =>
          project.hasRecord(val) || 'Path does not exist in current project!',
      ]"
    ></q-input>
    <q-input
      ref="icsRef"
      dense
      label="File Identification"
      v-model="ics"
      @blur="validate"
      :rules="[(val) => !!val || 'Field is required']"
    ></q-input>
    <q-input
      ref="nameRef"
      dense
      label=" File Name"
      v-model="fileName"
      @blur="validate"
      :rules="[(val) => !!val || 'Field is required']"
    ></q-input>
    <div class="q-mb-md q-mt-md">
      <span>File specific: </span> <span> {{ getFirstLine }}</span>
    </div>
    <div class="row no-wrap">
      <div class="col" v-for="group in flags">
        {{ group.column }}
        <div>
          <q-radio
            dense
            style="width: 100%"
            v-for="row in group.rows"
            v-model="group.set"
            :val="row.label"
            :label="row.label"
            :disable="row.ro"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script
  src="./newFileDialogCls.coffee"
  lang="coffee"
  type="text/coffeescript"
/>

<style scoped></style>
