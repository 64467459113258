<template>
  <q-page style="min-height: 100px; height: 100%">
    <q-banner
      v-show="isReqFile"
      class="q-mb-md q-pa-xs req-header"
      style="height: 100px"
    >
      <div class="reqtitle">{{ displayName }}</div>
      <div>({{ displayPath }})</div>
      <div class="declaration">{{ dimensionValue }}</div>
    </q-banner>
    <div id="editedText" :style="cssVars">
      <textarea :id="textAreaId" v-model="textToEdit" class="textAreaStyle">
      </textarea>
    </div>
  </q-page>
</template>

<script lang="coffee" src="./editorCls.coffee"></script>

<style lang="sass" src="./editorStyle.sass" />
